<template>
  <div class="medialib" style="height:100%">
    <v-app-bar flat style="height:64px">
      <v-btn-toggle v-model="layout" mandatory>
        <v-btn value="list">
          <v-icon>mdi-view-list-outline</v-icon>
        </v-btn>
        <v-btn value="grid">
          <v-icon>mdi-view-grid-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-divider vertical class="medialib_appbar-divider" />

      <div class="medialib__filters">
        <v-select
          v-if="accept === '*/*'"
          :items="mediaTypes"
          :label="translations.labels.medialib_mediaTypesFilterPlaceholder"
          item-value="value"
          item-text="label"
          v-model="filters.mediaType"
          solo
          single-line
          clearable
          class="medialib__filter"
        ></v-select>

        <v-select
          :items="dates"
          :label="translations.labels.medialib_datesFilterPlaceholder"
          item-value="value"
          item-text="label"
          v-model="filters.yearMonth"
          solo
          single-line
          clearable
          class="medialib__filter"
        ></v-select>
      </div>

      <v-btn
        :title="translations.labels.medialib_uploadBoxTitle"
        :disabled="inTrashCan"
        class="medialib__appbar-action"
        icon
        @click="toggleShowUploadBox"
        :class="showUploadBox ? 'medialib__appbar-action--toggled' : ''"
      >
        <v-icon>mdi-upload</v-icon>
      </v-btn>

      <v-btn
        :title="translations.labels.medialib_newFolderTitle"
        :disabled="inTrashCan"
        class="medialib__appbar-action"
        icon
        color="primary"
        @click="createFolder"
      >
        <v-icon>mdi-folder-plus-outline</v-icon>
      </v-btn>

      <v-checkbox
              v-model="compress"
              color="primary"
              :label="translations.labels.medialib_compressCheckboxTitle"
              hide-details
            ></v-checkbox>

      <v-btn
        v-show="false"
        :title="translations.labels.medialib_trashTitle"
        :disabled="inTrashCan"
        class="medialib__appbar-action"
        icon
        @click="createFolder"
      >
        <v-icon color="error">mdi-trash-can-outline</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <div class="medialib__filters">
        <v-text-field
          :placeholder="translations.labels.medialib_searchFilterPlaceholder"
          v-model="filters.search"
          append-icon="mdi-magnify"
          solo
          single-line
        ></v-text-field>
      </div>
    </v-app-bar>
    <div class="medialib__fill-width-container">
      <v-row no-gutters style="height:100%">
        <v-col cols="3" class="medialib__tree-container">
          <div v-if="tree && root && activeFolders">
            <v-treeview
              v-model="tree"
              :items="foldersTree"
              :active="activeFolders"
              :open.sync="openFolders"
              activatable
              hoverable
              @update:active="selectFolder"
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon
                  class="medialib__tree-icon"
                  :class="nodeIconClasses(item, open)"
                >
                  {{ nodeIcon(item, open) }}
                </v-icon>
              </template>
              <template v-slot:label="{ item, open }">
                <span :class="nodeClasses(item, open)">{{ item.name }}</span>
              </template>
            </v-treeview>
          </div>
        </v-col>

        <v-col
          cols="9"
          class="medialib__contents-container"
        >
          <div class="medialib__contents-warning-container">
            <v-alert v-if="missingFile" dense type="warning" dismissible>
              {{
                this.translations.labels.medialib_fileMissingFromMediaLibrary
              }}
            </v-alert>
          </div>
          <div class="medialib__contents-container-loader-container">
            <v-progress-circular
              v-if="loadingContents"
              :size="24"
              color="primary"
              indeterminate
              class="medialib__contents-container-loader"
            ></v-progress-circular>
          </div>
          <MediaItems
            :key="contentsContainerId"
            :layout="layout"
            :selected="selected"
            :items="filteredContents"
            :multiple="multiple"
            :show-upload-box="showUploadBox && !inTrashCan"
            :propagate-dbl-click-file="propagateDblClickFile"
            :is-dialog="isDialog"
            @mediaitems:getpublicurl="getPublicUrl"
            @mediaitems:openfolder="openFolder"
            @mediaitems:editfile="editFile"
            @mediaitems:rename="rename"
            @mediaitems:download="download"
            @mediaitems:delete="deleteItem"
            @mediaitems:restore-file="restoreFile"
            @mediaitems:compress-file="compressFile"
            @mediaitems:move="move"
            @mediaitems:update-selected="updateSelected"
            @mediaitems:uploadfile="onFileUploadSelected"
            @mediaitems:multi-uploadfile="onMultiFileUploadSelected"
            @mediaitems:closeuploadbox="showUploadBox = false"
            @mediaitems:dblclickfile="dblclickfile"
          />
        </v-col>
      </v-row>
    </div>

    <v-system-bar window class="pa-0">
      <v-container>
        <v-row no-gutters>
          <v-col cols="2"> </v-col>
          <v-col cols="10" class="medialib__contents-footer-container"
            >&nbsp;</v-col
          >
        </v-row>
      </v-container>
    </v-system-bar>
    <MediaEdit
      :open="openEditItem"
      :item="editItem"
      @mediaedit:close="mediaEditClose"
    />
  </div>
</template>

<script>
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";
import MediaItems from "@/components/medialib/items/MediaItems";
import MediaEdit from "@/components/medialib/items/MediaEdit";
import { Utility } from "@/lib/Utility";
import { MediaUtility } from "@/lib/MediaUtility";

@Component({
  name: "MediaLib",
  inject: {
    fileService: "fileService",
    folderService: "folderService",
    toastService: "toastService",
  },
  components: {
    MediaItems,
    MediaEdit,
  },
  props: {
    initiallySelected: {
      type: Array,
      default: () => [],
    },
    initialFolder: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "*/*",
    },
    showTrashFolder: {
      type: Boolean,
      default: false,
    },
    missingFile: {
      type: Boolean,
      default: false,
    },
    propagateDblClickFile: {
      type: Boolean,
      default: false,
    },
    initialLayout: {
      type: String,
      default: "grid",
    },
    isDialog: {
      default: false,
      type: Boolean
    },
  },
  watch: {
    selected: function (newVal) {
      this.$emit("medialib:update-selected", newVal, this.initiallySelected);
    },
  },
})
export default class MediaLib extends mixins(Translations) {
  TRASH_FOLDER = {
    id: "trash",
    name: "Trash Can",
    children: [],
  };

  selected = [];

  compress = true;

  root = null;
  currentFolder = null;
  showUploadBox = false;
  tree = [];
  openFolders = ["root"];
  activeFolders = ["root"];
  layout = "grid";
  filters = {
    mediaType: null,
    yearMonth: null,
    search: null,
  };
  foldersTree = [
    {
      id: "root",
      name: "/",
      children: [],
    },
  ];
  foldersPathRef = {};
  files = [];
  loadingTreeRequests = [];
  loadingContentsRequests = [];
  contentsContainerId = null;
  openEditItem = false;
  editItem = null;

  get inTrashCan() {
    return this.currentFolder === this.TRASH_FOLDER.id;
  }

  get mediaTypes() {
    const mimeTypes = Utility.distinct(
      this.contents.map((c) => {
        if (c.isDir) {
          return "dir";
        }
        return c.mimeType.split("/")[0];
      })
    );
    return mimeTypes.map((m) => {
      return {
        value: m,
        label: this.translations.labels[`medialib_mainTypeLabel_${m}`],
      };
    });
  }

  get dates() {
    const allPeriods = Utility.distinct(
      this.contents
        .map((c) => {
          return c?.yearMonth;
        })
        .filter((e) => !!e)
    );
    return allPeriods.map((m) => {
      const pieces = m.split("/");
      const year = pieces[0];
      const month = pieces[1];
      const label = this.translations.labels[`month_${month}`] + ` ${year}`;
      return {
        value: m,
        label: label,
      };
    });
  }

  selectFolder(folders) {
    if (folders.length == 0) {
      this.activeFolders = [this.currentFolder];
      return;
    }
    const folderId = folders && folders.length > 0 ? folders[0] : this.root.id;
    this.openFolder(this.findFolderById(folderId), true);
  }

  async getPublicUrl(item) {
    await navigator.clipboard.writeText(item.publicUrl);
    this.toastService.success(this.translations.labels.medialib_publicUrlCopiedToClipboard);
  }

  async openFolder(folder, dontSetActiveFolder = false) {
    if (this.currentFolder == folder.id) {
      return;
    }
    this.currentFolder = folder.id;
    await this.refreshFiles();
    if (!dontSetActiveFolder) {
      this.activeFolders = [this.currentFolder];
    }
  }

  dblclickfile(file) {
    this.$emit("medialib:dblclickfile", file);
  }

  showErrorMessage(e, defaultMessage = null) {
    defaultMessage = defaultMessage ?? this.translations.errors.somethingWrong;
    if (e.code === 422) {
      const msg = [];
      for (const i in e.details.Fields) {
        const errors = e.details.Fields[i]?.Errors ?? [];
        msg.push(errors.map((r) => r.Message).join("; "));
      }
      this.toastService.error(msg.join("\r\n"), 6);
    } else if (e?.description) {
      this.toastService.error(e?.description, 6);
    } else {
      this.toastService.error(defaultMessage);
    }
  }

  async renameFile(item) {
    let newFileName = window.prompt(
      this.translations.labels.medialib_promptRenameFile,
      item.name
    );
    if (newFileName === null) {
      return;
    }
    if (
      newFileName === "" ||
      newFileName === null ||
      typeof newFileName === "undefined"
    ) {
      this.toastService.error(
        this.translations.errors.medialib_invalidFolderName
      );
      return;
    }

    await this.fileService.rename(item, newFileName);
    await this.refreshTree();
    await this.refreshFiles();
  }

  async renameFolder(item) {
    let newFolderName = window.prompt(
      this.translations.labels.medialib_promptRenameFolder,
      item.name
    );
    if (newFolderName === null) {
      return;
    }
    if (
      newFolderName === "" ||
      newFolderName === null ||
      typeof newFolderName === "undefined"
    ) {
      this.toastService.error(
        this.translations.errors.medialib_invalidFolderName
      );
      return;
    }

    await this.folderService.rename(item, newFolderName);
    await this.refreshTree();
    await this.refreshFiles();
  }

  async rename(item) {
    try {
      if (item.isDir) {
        await this.renameFolder(item);
        await this.refreshTree();
      } else {
        await this.renameFile(item);
      }
      await this.refreshFiles();
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  download(item) {
    if (item.isDir) {
      return;
    }

    const medialib = document.querySelector(".medialib");
    const link = document.createElement("a");
    const url = item?.publicUrl ?? item.url;

    link.href = MediaUtility.getFileUrl(url + "?fn=" + item.downloadName);
    link.classList.add("medialib__hidden");
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.download = item.downloadName;

    medialib.appendChild(link);
    link.click();
    medialib.removeChild(link);
  }

  async deleteItem(item) {
    try {
      if (item.isDir) {
        await this.folderService.remove(item.id);
        await this.refreshTree();
      } else {
        if (item.isDeleted) {
          const result = window.confirm(
            this.translations.labels.medialib_promptConfirmDelete
          );
          if (result !== true) {
            return;
          }
        }
        await this.fileService.remove(item.id);
      }
      await this.refreshFiles();
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  async restoreFile(item) {
    try {
      if (item.isDir) {
        return;
      }
      if (!item.isDeleted) {
        return;
      }
      await this.fileService.restore(item.id);
      await this.refreshFiles();
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  async compressFile(item) {
    try {
      if (item.isDir) {
        return;
      }
      if (item.isDeleted) {
        return;
      }
      await this.fileService.compress(item.id);
      await this.refreshFiles();
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  async move(destination, item) {
    if (destination.id === item.id) {
      return;
    }
    try {
      if (item.isDir) {
        await this.folderService.move(item, destination);
        await this.refreshTree();
      } else {
        await this.fileService.move(item, destination);
      }
      await this.refreshFiles();
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  updateSelected(selected) {
    this.selected = selected;
  }

  updateContentsContainer() {
    this.contentsContainerId = Utility.uid("cci");
  }

  findFolderById(id, folder = null) {
    folder = folder ?? this.foldersTree;
    for (const i in folder) {
      const children = folder[i]?.children ?? [];
      if (folder[i].id == id) {
        return folder[i];
      } else if (children.length > 0) {
        const target = this.findFolderById(id, folder[i].children);
        if (target != null) {
          return target;
        }
      }
    }
    return null;
  }

  toggleShowUploadBox() {
    this.showUploadBox = !this.showUploadBox;
  }

  async createFolder() {
    let newFolderName = window.prompt(
      this.translations.labels.medialib_promptNewFolder,
      this.translations.labels.medialib_promptNewFolderDefault
    );
    if (newFolderName === null) {
      return;
    }
    if (
      newFolderName === "" ||
      newFolderName === null ||
      typeof newFolderName === "undefined"
    ) {
      this.toastService.error(
        this.translations.errors.medialib_invalidFolderName
      );
      return;
    }

    try {
      await this.folderService.create({
        idParent: this.currentFolder,
        name: newFolderName,
      });
      await this.refreshAll();
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  nodeIcon(item, open) {
    if (item.id === this.root.id) {
      return this.loadingTree ? "mdi-loading" : "mdi-folder-home-outline";
    }
    if (item.id === "trash") {
      return "mdi-trash-can-outline";
    }
    if (open) {
      return "mdi-folder-open-outline";
    }
    return item.isSystem ? "mdi-folder-cog-outline" : "mdi-folder-outline";
  }

  nodeClasses(item) {
    return {
      "deep-orange--text text--darken-2": item.id === "trash",
      "blue--text text--darken-4": item.isSystem,
    };
  }

  nodeIconClasses(item) {
    return {
      ...this.nodeClasses(item),
      "medialib__tree-icon--rotating":
        item.id === this.root.id && this.loadingTree,
    };
  }

  mediaEditClose() {
    this.openEditItem = false;
    this.editItem = null;
  }

  isSelectable(item, isDir) {
    if (!this.selected) {
      return false;
    }
    if (this.selected.includes(item)) {
      return true;
    }
    if (this.accept === "*/*") {
      return true;
    }
    const acceptTypes = (this?.accept ?? "").split(",");
    if (isDir) {
      return acceptTypes.includes("dir");
    }
    const thisType = item?.mimeType ?? "invalid/type";
    const thisMainType = `${thisType.split("/")[0]}/*`;

    return acceptTypes.includes(thisMainType) || acceptTypes.includes(thisType);
  }

  get contents() {
    if (!this.root) {
      return [];
    }
    if (!this.currentFolder) {
      return this.files;
    }
    const folder = this.findFolderById(this.currentFolder);
    const hasParent = (folder.id !== this.root.id && folder?.idParent) ?? false;

    // create new array of items
    return [
      // current folder as parent folder (".." element)
      ...(hasParent
        ? [
            {
              id: folder.idParent,
              isDir: true,
              isParentDir: true,
              name: "..",
              isSelectable: false,
            },
          ]
        : []),
      // add this folder children as folders in items to show
      ...(folder?.children ?? []).map((f) => {
        const updateDate = new Date(f.updateDate);
        const yearMonth =
          updateDate.getFullYear() + "/" + (updateDate.getMonth() + 1);
        return {
          ...f,
          isDir: true,
          isSelectable: this.isSelectable(f, true),
          refDate: updateDate,
          yearMonth: yearMonth,
          isParentDir: false,
        };
      }),
      // add all other files
      ...this.files.map((f) => {
        const path =
          typeof this.foldersPathRef[f.idFolder] !== "undefined"
            ? this.foldersPathRef[f.idFolder]
            : "";

        const isSelectable = this.isSelectable(f, false);

        return MediaUtility.fileMapping(f, isSelectable, path);
      }),
    ];
  }

  get filteredContents() {
    return this.contents.filter((c) => {
      if (this.isSelected(c)) {
        return true;
      }
      if (!c.isDir && !this.isSelectable(c, false)) {
        return false;
      }
      const mediaType = c.isDir ? "dir" : c?.mimeType ?? "";
      const period = c?.yearMonth ?? "";
      const name = (c?.name ?? "").toLowerCase();

      const mediaTypeFilter = this.filters?.mediaType ?? null;
      const periodFilter = this.filters?.yearMonth ?? null;
      const searchFilter = this.filters?.search ?? null;

      if (mediaTypeFilter !== null && !mediaType.includes(mediaTypeFilter)) {
        return false;
      }

      if (periodFilter !== null && period !== periodFilter) {
        return false;
      }

      if (
        searchFilter !== null &&
        !name.includes(this.filters.search.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
  }

  get loadingTree() {
    return this.loadingTreeRequests.length > 0;
  }

  get loadingContents() {
    return this.loadingContentsRequests.length > 0;
  }

  isSelected(item) {
    return (this?.selected ?? []).findIndex((i) => i.id === item.id) >= 0;
  }

  buildPathRef(folders = null, prefix = "") {
    if (folders === null) {
      this.foldersPathRef = {};
      folders = this.foldersTree;
    }

    for (const i in folders) {
      const folder = folders[i];
      const children = folder?.children ?? [];
      this.foldersPathRef[folder.id] = `${prefix}/${folder.name}`;

      this.foldersPathRef[folder.id] = this.foldersPathRef[folder.id].replace(
        "//",
        "/"
      );

      if (children.length > 0) {
        this.buildPathRef(children, `${this.foldersPathRef[folder.id]}`);
      }
    }
  }

  async refreshTree() {
    this.loadingTreeRequests.push(1);
    this.root = await this.folderService.list();
    this.root.name = "/";

    this.foldersTree = [this.root];
    if (this.currentFolder === "root") {
      this.currentFolder = this.root.id;
    }

    this.buildPathRef();
    if (this.showTrashFolder) {
      this.foldersTree.push(this.TRASH_FOLDER);
    }

    // mediaType and yearMonth filters depends on the current change;
    // we reset them on folder change
    this.filters.mediaType = null;
    this.filters.yearMonth = null;
    // this.filters.search = null;

    this.$nextTick(() => {
      this.loadingTreeRequests.pop();
      if (!this.openFolders.includes("root")) {
        this.openFolders.push("root");
      }
    });
  }

  async refreshFiles() {
    this.loadingContentsRequests.push(1);

    if (this.currentFolder !== "trash") {
      this.files = await this.fileService.listInFolder(this.currentFolder);
    } else {
      this.files = await this.fileService.listDeleted(this.currentFolder);
    }

    this.$nextTick(() => {
      this.selected = [];
      this.loadingContentsRequests.pop();
      this.updateContentsContainer();
    });
  }

  pathToFolder(folder, tree) {
    for (const i in tree) {
      const currFolder = tree[i];
      if (currFolder.id === folder) {
        return [folder];
      } else if ((currFolder?.children ?? []).length > 0) {
        const path = this.pathToFolder(folder, currFolder.children);
        if (path !== null) {
          return [currFolder.id, ...path];
        }
      }
    }
    return null;
  }

  async refreshAll() {
    await this.refreshTree();
    await this.refreshFiles();
  }

  async onFileUploadSelected(selectedFile, dontRefreshOnSuccess = false) {
    if (typeof selectedFile === "undefined") {
      this.toastService.error(
        this.translations.errors.medialib_cannotUploadFile
      );
      return;
    }

    const body = {
      attachedFile: selectedFile,
      fileName: selectedFile.name,
      downloadName: selectedFile.name,
      compress: this.compress,
      idFolder: this.currentFolder,
    };
    try {
      const uploadedFile = await this.fileService.create(body);
      if (!dontRefreshOnSuccess) {
        await this.refreshFiles();
      }
      this.selected.push(uploadedFile);
    } catch (e) {
      this.showErrorMessage(
        e,
        this.translations.errors.medialib_cannotUploadFile
      );
    }
  }

  async onMultiFileUploadSelected(selectedFiles) {
    for (let i = 0; i < selectedFiles.length; ++i) {
      await this.onFileUploadSelected(selectedFiles[i], true);
    }
    const selectedCopy = Utility.clone(this.selected);
    await this.refreshFiles();
    if (this.multiple) {
      this.selected = selectedCopy;
    } else {
      this.selected = [selectedCopy.pop()];
    }
  }

  async created() {
    this.TRASH_FOLDER.name = this.translations.labels.medialib_trashCan;

    this.layout = this.initialLayout;

    this.selected = this.initiallySelected;

    this.currentFolder = this.initialFolder ?? "root";
    this.activeFolders = [this.currentFolder];

    await this.refreshAll();

    this.openFolders = this.pathToFolder(this.currentFolder, this.foldersTree);
  }
}
</script>

<style lang="scss">
.v-treeview-node__content {
  cursor: pointer;

  .v-treeview-node__label {
    line-height: 100%;
    text-align: left;
    font-size: 16px;
    overflow: hidden;
  }
}

.v-toolbar__content {
  height: 64px !important;
}

@media (max-width: 1600px) {
  .v-text-field.v-text-field--solo .v-label {
    font-size: 12px !important;
  }

  #app .v-select__selection.v-select__selection--comma {
    font-size: 12px !important;
  }

  .v-text-field.v-text-field--solo .v-input__control input {
    font-size: 12px !important;
  }
}
</style>
<style lang="scss" scoped>

.v-alert{
  line-height: 1;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.medialib {
  &__hidden {
    display: none;
  }
  &__fill-width-container{
    height: calc(100% - 116px);
    width: 100%;
  }
  &__tree-icon {
    &--rotating {
      animation: rotating 0.75s linear infinite;
    }
  }

  &__contents-warning-container {
    padding: 5px 10px 0 10px;
  }

  &__contents-container-loader-container {
    display: inline-block;
    height: 32px;
  }

  &__contents-container-loader {
    margin-top: 50;
    margin-bottom: 50;
  }

  &__tree-container,
  &__contents-container,
  &__contents-footer-container {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }

  &__tree-container{
    max-height: 100%;
    overflow-y: auto;
  }
  &__contents-container {
    max-height: 100%;
    overflow-y: scroll;
  }
  &_appbar-divider {
    margin-left: 10px;
    margin-right: 10px;
  }
  &__filters {
    margin-top: 30px;
  }
  &__filter {
    float: left;
    width: 150px;
    margin-right: 10px !important;
  }
  &__appbar-action {
    margin-right: 10px;

    &--toggled {
      background: rgba(0, 0, 0, 0.15);
    }
  }
  @media (min-width: 1300px) {
    &__filter {
      width: 175px;
    }
  }
  @media (min-width: 1600px) {
    &__filter {
      width: auto;
    }
  }
}
</style>